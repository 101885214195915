body{
    display: block;
    background-color: whitesmoke;
    /* mobile website */
    max-width: 67em;
    margin: 0 auto;
}


.hidden{
    display: none;
}

.Announcements-ViewPage>main>ul>li::-webkit-scrollbar{
    display: none;
    width: 0;
    height: 0;
}


.Announcements-ViewPage>main::-webkit-scrollbar{
    display: none;
    width: 0;
    height: 0;
}


.NoBars::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }


.LandingPage-PageView{
    display: grid;
    align-self: center;
    margin: 0 auto;
}

.HeroBanner-Container{
    display: grid;
}
.HeroBanner-Container>header{
    display: grid;
    justify-content: center;
    align-items: center;
    
}


.HeroBanner-Container>header picture{
    grid-row: 1/2;
    grid-column: 1/2;

}

.HeroBanner-Container>header picture img{
    width: 100%;
}



.HeroBanner-Container>header h1{
    color: white;
    font: 1.2em sans-serif;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
}


.HeroBanner-Container>header h2{
    color: white;
    font: 2em sans-serif;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);

}

.WebsiteName{
    display: grid;
    grid-row: 1/2;
    grid-column: 1/2;
    align-self: flex-end;
    margin-bottom: 70px;
}

.MainNav-Component{
    position: relative;
    background-color: #79A27D;
    height: auto;
    padding-bottom: 10px;
    padding-top: 10px;
    /* border-radius: 20px 20px 0 0 ;
    margin-top: -30px; */
    z-index: 5px;

}

.MainNav-Component>header{
    display: grid;
    grid-template-columns: 70px 1fr;
    color: white;
    justify-items: center;
    align-items: center;
}

.MainNav-Component>header img{
    cursor: pointer;
}

.MainNav-Component>header h1{
    margin-left: -70px;
}

.NavList-Container {
    font: 1.5em sans-serif;

}

.NavList-Container ul{
    padding-top: 20px;
    display: grid;
    row-gap: 20px;
}

.NavList-Container ul a {
    color: white;
    text-decoration: none;
}

.contactUs-container{
    display: grid;
    row-gap: 30px;
    margin-top: 50px;
    padding-bottom: 30px;

}

.contactUs-container>li{
    display: grid;
    grid-template-columns: 1fr 120px;
    align-items: center;
    
}

.contactUs-container>li p{
    font: 1.2em sans-serif;
    text-align: left;
    padding-left: 70px;
    color: white;
}

/* Schedule-Component------------------------------------- */

.Schedule-Component{
    display: grid;
    row-gap: 30px;
    background-color: #A1C5A4;
    justify-items: center;
    padding: 50px 20px 30px 20px;
    margin-top: -20px;
    border-radius: 20px 20px 0 0;
}

.Schedule-Component>header h1{
    font: 1.5em sans-serif;
    color: white;
    padding: 13px;
    max-width: 380px;
    justify-self: center;
}

.Schedule-Component>main>section{
    display: grid;
    row-gap: 3px;
    padding-bottom: 20px;
}

.Schedule-Component>main>section h2{
    font: 1.4em sans-serif;
    color: white;
    text-align: left;
}

.Schedule-Container{
    display: grid;
    row-gap: 5px;
    background-color: white;
    border-radius: 7px;
    padding: 20px;
    /* max-width: 70vw; */
   

  }
.Schedule-Container>li{
    display: grid;
    grid-template-columns: 10px 1fr 2fr;
    text-align: left;
    padding:  0 10px ;
    height: 40px;
    align-items: center;
    border-radius: 7px;


}

.Schedule-Container>li p:last-child{
    text-align: right;
}



/* Announcements ---------------------------------------------- */
.Announcements-ViewPage{
    display: grid;
    padding: 30px;
    background-color: white;

}



.Announcements-ViewPage> ul{
    position: relative;
    display: grid;
    /* column-gap: 10px; */
    margin: 0;
    overflow: scroll;
    max-width: 65em;
    
}


.Announcements-ViewPage> ul li{
    display: grid;
    margin: 15px;
    overflow-y: scroll;
    row-gap: 10px;
    background-color: white;
    align-content: center;
    padding: 20px;
    border-radius: 7px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    min-width: 270px;
    text-align: left;
    
}



.Announcements-ViewPage> ul li{
    font: 1em sans-serif;
    color: black;

}

.Announcements-ViewPage> ul li {
    display: grid;
    row-gap: 10px;
    height: auto;
}

.Announcements-ViewPage> button{
    background-color: #79A27D;
    font: 1em sans-serif;
    color: white;
    border: none;
    height: 35px;
    padding: 10px;
    border-radius: 3px;
}



.Sacraments-Container{
    background-color: whitesmoke;
}


.Sacraments-Container>h1{
    font: 2em sans-serif;
    padding: 20px;
    margin-bottom: 30px;
}


.Sacraments-Container>ul{
    display: grid;
}


.Sacraments-Container>ul li {
    display: grid;
    align-content: flex-start;
    padding-bottom: 40px;
}




.Sacraments-Container>ul h2{
    color: white;
    align-content: center;
    background-color: #79A27D;
    font: 1.5em sans-serif;
    height: 45px;
    box-shadow: 0px -3px 3px rgba(0, 0, 0, 0.137);
    border-radius: 7px 7px 0 0;
    margin-top: -5px;
    text-align: left;
    cursor: pointer;
    padding: 10px;
    padding-left: 20px;

}


.Sacraments-Container>ul li section{
    display: grid;
    row-gap: 10px;
    padding: 30px;
    text-align: left;
}

.Sacraments-Container>ul li img{
    width: 100%;
    height: 250px;
}

.FeedBack-Container{
    display: grid;
    row-gap: 20px;
    font: 1.3em sans-serif;
    padding-top: 20px;
}

.FeedBack-Container>header h1{
    display: none;
}

.FeedBack-Container>main{
    display: flex;
    column-gap: 10px;
    justify-self: center;
}

.FeedBack-Container>main button{
    height: 35px;
    width: 70px;
    background-color: #67886A;
    color: white;
    border: none;
    border-radius: 3px;
}


.Bulletin-Container{
    display: grid;
    grid-template-rows: 50px;
    justify-items: center;
    padding-bottom: 50px;
    padding-top: 50px;
}

.Bulletin-Container>ul {
    display: grid;
    row-gap: 30px;
}
.Bulletin-Container>ul li {
    display: grid;
    row-gap: 5px;
    justify-items: center;
    background: white;
    padding: 20px;
    border-radius: 10px;
    align-items: center;
    
}

.Bulletin-Container>ul li img{
    width: 240px;
    height: 270px;
    border: black 1px solid;

}

.Bulletin-Container>ul li a{
    font: 1.2em sans-serif;
    color: white;
    text-decoration: none;
    background: #67886A;
    padding: 10px;
    border-radius: 3px;
}

.SundayHomily{
    /* height: 50vh; */
    max-width: 65em;
    overflow: scroll;
    display: grid;
    margin: 10px;
    border-radius: 7px;
    justify-items: left;
    justify-self: center;
    text-align: left;
    overflow: hidden;

}
.SundayHomily main{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

.SundayHomily>footer{
    display: grid;
    justify-self: center;
}

.SundayHomily>footer>h3{
    font: 1em sans-serif;
}

.SafeEnvironment{
    display: grid;
    row-gap: 10px;
    padding: 40px;
    background-color: #A1C5A4;
    border-radius: 7px;
    max-width: 65em;
    justify-self: center;
    align-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.SafeEnvironment>h1{
    font: 2em sans-serif;
    color: white;
    padding: 10px;
}
.SafeEnvironment>p{
    font: 1.1em sans-serif;
    color: black;
    background-color: white;
    padding: 20px;
    border-radius: 7px;
    text-align: left;

}


@media screen and (min-width: 1100px) {
    .Sacraments-Container{
        width: 51em;
        justify-self: center;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.322);
        border-radius: 30px;
    }

    .Sacraments-Container>h1{
       
        margin-bottom: 0px;
    }
.Sacraments-Container>ul li {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 0px;
}

.HeroBanner-Container>header h1 {
    font: 2em sans-serif;
}

.HeroBanner-Container>header h2 {
    font: 3.3em sans-serif;
}

.Announcements-ViewPage {
    justify-content: center;
}
    

.Announcements-ViewPage>main ul{
display: flex;
}
body{
    max-width: none;
}

.MainNav-Component>header{
    width: 300px;
}

.MainNav-Component{
    width: 300px;
}

.HeroBanner-Container>header picture img {
    margin-bottom: -40px;
}


    
}

